import tw, { styled } from "twin.macro"
import { RichText } from "../RichText/RichText"
import { StyledContainer } from "../Styled/Container"
import { TabList, Tab } from "@reach/tabs"
import { Table } from "../Table/Table"

export const Wrapper = styled(StyledContainer)`
  ${tw`py-3-2 md:py-8 max-w-85`}
`

export const Title = tw.h1`
  text-12 md:text-18 text-center font-bold uppercase mb-1-6
`

export const Description = styled(RichText)`
  ${tw`max-w-63 mx-auto pb-8`}
`

export const StyledTabList = styled(TabList)`
  ${tw`flex flex-row`}
`

export const StyledTab = styled(Tab)`
  ${tw`border border-grey-dim w-full py-1-6 border-r-0`}
  ${({ isSelected }) => (isSelected ? tw`bg-black text-white border-black` : tw`bg-white text-black`)}
    :last-of-type {
    ${tw`border-r`}
  }
`

export const CategoryWrapper = styled.div`
  ${tw`grid grid-cols-1 md:px-4 my-4`}
`

export const CategoryTitle = tw.h2`
  col-span-1 text-16 font-bold uppercase lg:mb-2-4 max-w-22 px-2
`

export const TablesWrapper = tw.div`
  col-span-2 
`

export const Tables = tw.div`
  flex flex-col md:flex-row w-full
`

export const StyledTable = styled(Table)`
  ${tw`md:mr-3 mb-3-2 md:mb-0`}
  :last-of-type {
    ${tw`mr-0 mb-0`}
  }
`

export const ImageWrapper = tw.div`
  border border-grey-dim
`

export const BorderWrapper = tw.div`
  border border-grey-dim md:py-0-1 border-t-0
`
